import React from 'react';

const LoadingSpinner: React.FC = () => {
  const spinnerStyle: React.CSSProperties = {
    borderTopColor: '#3498db',
    animation: 'spinner 1.5s linear infinite',
  };

  return (
    <div className="flex justify-center items-center h-64">
      <div
        style={spinnerStyle}
        className="ease-linear rounded-full border-8 border-t-8 border-gray-200 h-16 w-16"
      ></div>
      {/* Define keyframes globally or in a parent component */}
      <style>
        {`
          @keyframes spinner {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
        `}
      </style>
    </div>
  );
};

export default LoadingSpinner;
