import React, {useRef, useState} from 'react';
import { Link, NavLink } from 'react-router-dom';
import {AnimatePresence, motion} from 'framer-motion';
import { FaBars, FaTimes } from 'react-icons/fa';


import {ReactComponent as Logo} from '../../assets/images/CreativeProductionStudio_HighQuality.svg';
import navItems from '../../data/navItems';
import useOutsideClick from '../../hooks/UseOutsideClick';

const Navbar = React.memo(() => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useOutsideClick(dropdownRef, () => {
    if (isOpen) setIsOpen(false);
  });

  return (
    <nav className="bg-white text-black fixed w-full z-10 border-b border-gray-200">
      <div className="container mx-auto px-4 py-6 flex justify-between items-center relative">
        <div className="w-8 lg:w-12"></div>

        <div className="flex-1 flex justify-center font-cinzel text-lg md:text-5xl">
          <Link to="/">
            {/*<Logo className="h-16 md:h-24 lg:h-32 w-auto object-contain flex-shrink-0 p-2 bg-white rounded-full"/>*/}
            <h1>Creative Production Studio</h1>
          </Link>
        </div>

          <div className="w-8 lg:w-12 flex justify-end">
            <button
                onClick={toggleMenu}
                className="text-black focus:outline-none"
                aria-label="Toggle navigation menu"
                aria-expanded={isOpen}
                aria-controls="navigation-menu"
            >
              {isOpen ? <FaTimes size={24}/> : <FaBars size={24}/>}
            </button>
          </div>
      </div>

          <AnimatePresence>
            {isOpen && (
                <motion.div
                    id="navigation-menu"
                    ref={dropdownRef}
                    initial={{opacity: 0, scale: 0.95, y: -10}}
                    animate={{opacity: 1, scale: 1, y: 0}}
                    exit={{opacity: 0, scale: 0.95, y: -10}}
                    transition={{duration: 0.3}}
                    className="absolute top-full right-0 mt-2 w-48 bg-white border rounded shadow-lg"
                >
                  {navItems.map((item) => (
                      <NavLink
                          key={item.name}
                          to={item.path}
                          onClick={() => setIsOpen(false)}
                          className={({isActive}) =>
                              isActive
                                  ? 'block px-4 py-2 text-blue-500'
                                  : 'block px-4 py-2 text-black hover:bg-gray-100 transition duration-300'
                          }
                      >
                        <motion.span
                            whileHover={{
                              scale: 1.05,
                              filter: 'drop-shadow(0px 4px 6px rgba(128, 128, 128, 0.5))',
                            }}
                            className="font-sans"
                        >
                          {item.name}
                        </motion.span>
                      </NavLink>
                  ))}
                </motion.div>
            )}
          </AnimatePresence>
    </nav>
);
});

export default Navbar;
